(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){

$(function(){
  $('a[href^="#"]').click(function(){
    var speed = 500;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });
});

$(window).load(function(){
	$('.main_slide').slick({
    arrows: false,
		centerPadding: '0px', //前後のパディング
		autoplay: true, //オートプレイ
		autoplaySpeed: 6000, //オートプレイの切り替わり時間
		slidesToShow: 1,
		adaptiveHeight: true,
    fade: true,
	});
  $('.slick_next_mv').on('click', function(){
    $('.main_slide').slick('slickNext');
  });
  $('.slick_prev_mv').on('click', function(){
    $('.main_slide').slick('slickPrev');
  });
});

$(window).load(function(){
	$('.main_slide_sp').slick({
    arrows: false,
		centerPadding: '0px', //前後のパディング
		autoplay: true, //オートプレイ
		autoplaySpeed: 6000, //オートプレイの切り替わり時間
		slidesToShow: 1,
		adaptiveHeight: true,
    fade: true,
	});
  $('.slick_next').on('click', function(){
    $('.main_slide_sp').slick('slickNext');
  });
  $('.slick_prev').on('click', function(){
    $('.main_slide_sp').slick('slickPrev');
  });
});

$(function() {
	$('.slider').slick({
    arrows: false,
		centerPadding: '0px', //前後のパディング
		autoplay: true, //オートプレイ
		autoplaySpeed: 2000, //オートプレイの切り替わり時間
		slidesToShow: 5,
		responsive: [{
			breakpoint: 768,
			settings: {
				arrows: false, // 前後の矢印非表示
				centerMode: true,
				centerPadding: '0px',
				slidesToShow: 5
			}
		},
		{
			breakpoint: 480,
			settings: {
				arrows: false,
				centerMode: true,
				centerPadding: '0px',
				slidesToShow: 3
			}
		}]
	});
  $('.slick_next').on('click', function(){
    $('.slider').slick('slickNext');
  });
  $('.slick_prev').on('click', function(){
    $('.slider').slick('slickPrev');
  });
	var window_w = $(window).width();
	var w = $(".slick-slide").width();
	if(window_w < 631){
	  $("#arrows").css('width',w/2.8);
	}else{
	  $("#arrows").css('width',w*2);
	}
});



$(window).resize(function(){
  var w = $(".slick-slide").width();
  $("#arrows").css('width',w*2);
	var window_w = $(window).width();
	if(window_w < 631){
		$(".member_block .position").each(function(){
			var text = $(this).html();
			var r_text = text.replace(" / " , "<br>");
			$(this).html(r_text);
		});
		var h = $(window).height();
		$(".mainvisual").css("height",h);

	}else{
		$(".member_block .position").each(function(){
			var text = $(this).html();
			var r_text = text.replace("<br>" , " / ");
			$(this).html(r_text);
		});
		$(".member_block .message").removeAttr('style');
		$(".member_block .content").removeAttr('style');
		$(".mainvisual").css("height","auto");
	}
});

$(window).load(function(){
	var window_w = $(window).width();
	console.log("smt");
	if(window_w < 631){
		var h = $(window).height();
		$(".mainvisual").css("height",h);
    $(".mv_bg").css("height",h);
	}else{
	}
});


$(function(){
	$(".nav_btn").click(function(){
		$(".smt_nav").addClass("active");
	});
});
$(function(){
	$(".close_btn").click(function(){
		$(".smt_nav").removeClass("active");
	});
});
$(function(){
	$(".smt_nav ul li a").click(function(){
		$(".smt_nav").removeClass("active");
	});
});


$(function() {

});

function getSliderSettings(){
	$('.instagram').slick({
    arrows: false,
		centerPadding: '0px', //前後のパディング
		autoplay: true, //オートプレイ
		autoplaySpeed: 2000, //オートプレイの切り替わり時間
		centerMode: true,
		slidesToShow: 3,
		responsive: [{
			breakpoint: 768,
			settings: {
				arrows: false, // 前後の矢印非表示
				centerMode: true,
				centerPadding: '0px',
				slidesToShow: 4
			}
		},
		{
			breakpoint: 480,
			settings: {
				arrows: false,
				centerMode: true,
				centerPadding: '0px',
				slidesToShow: 3
			}
		}]
	});
  $('.slick_next_insta').on('click', function(){
    $('.instagram').slick('slickNext');
  });
  $('.slick_prev_insta').on('click', function(){
    $('.instagram').slick('slickPrev');
  });
}


//インスタ取得
$(function(){
    var $container = $(".instagram");
    var html = "";

    $.ajax({
        url: "common/instagram.php",//PHPファイルURL
        type:"POST",
        dataType: "json"
    }).done(function(data){
        //通信成功時の処理
        $.each(data.data,function(i,item){
            var imgurl = item.images.low_resolution.url; //低解像度の画像のURLを取得
            var link = item.link; //リンクを取得
            html += "<li><a href='" + link + "' target='_blank'><img src='" + imgurl + "'></a></li>";
        });
    }).fail(function(){
        //通信失敗時の処理
        html = "<li>画像を取得できまへん。</li>";
    }).always(function(){
        //通信完了時の処理
        $container.html(html);
        $('.instagram').slick(getSliderSettings());
    });
});

//インスタ取得
$(function(){
    var $container = $(".instagram_pc");
    var html = "";

    $.ajax({
        url: "common/instagram.php",//PHPファイルURL
        type:"POST",
        dataType: "json"
    }).done(function(data){
        //通信成功時の処理
        $.each(data.data,function(i,item){
            var imgurl = item.images.low_resolution.url; //低解像度の画像のURLを取得
            var link = item.link; //リンクを取得
            html += "<li><a href='" + link + "' target='_blank'><img src='" + imgurl + "'></a></li>";
        });
    }).fail(function(){
        //通信失敗時の処理
        html = "<li>画像を取得できまへん。</li>";
    }).always(function(){
        //通信完了時の処理
        $container.html(html);
    });
});

$(function() {
    var topBtn = $('.back_to_top');
    //スクロールが100に達したらボタン表示
    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            topBtn.addClass("on");
        } else {
            topBtn.removeClass("on");
        }
    });
});

$(window).load(function(){
  if ( $('.error').length ) {
    var h =$('.error').offset().top;
    var target = h - 54;
    console.log(target);
  	$("html,body").animate({scrollTop:target});
  }
});

$(window).load(function(){
    var nav = jQuery('nav');　//固定したいDivのIDを入力

    // メニューのtop座標を取得する
    var offsetTop = $(".mainvisual").height();
    console.log(offsetTop);
    var floatMenu = function() {
      // スクロール位置がメニューのtop座標を超えたら固定にする
      if (jQuery(window).scrollTop() > offsetTop) {
        nav.addClass('fixed');
      } else {
        nav.removeClass('fixed');
      }
    };
    jQuery(window).scroll(floatMenu);
    jQuery('body').bind('touchmove', floatMenu);
  });

},{}]},{},[1])

